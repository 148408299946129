import React, { useState } from 'react';
import Resizer from 'react-image-file-resizer';

const ImageUploader = ({id, value, onChange}) => {
  if (!value)
    value = [];
  const [resizedImages, setResizedImages] = useState(value);
  const [selectedImage, setSelectedImage] = useState(null); // Estado para imagem selecionada

  // Função para lidar com o upload de imagens
  const handleImageUpload = (event) => {
    const files = event.target.files;

    // Para cada arquivo, redimensionar e adicionar à lista de imagens
    Array.from(files).forEach((file) => {
      // Redimensionando a imagem
      Resizer.imageFileResizer(
        file,
        800, // Largura máxima
        600, // Altura máxima
        'JPEG', // Formato de imagem
        75, // Qualidade
        0, // Orientação
        (uri) => {
          setResizedImages((prevResizedImages) => {
          	const newResizedImages = [...prevResizedImages, uri];
          	onChange(id, newResizedImages);
          	return newResizedImages;
          }); // Armazenar a imagem redimensionada
        },
        'base64' // Retornar como string base64
      );
    });
  };

  // Função para excluir uma imagem
  const handleDeleteImage = (index) => {
    const newResizedImages = resizedImages.filter((_, i) => i !== index);
    onChange(id, newResizedImages);
    setResizedImages(newResizedImages);
  };

  // Função para selecionar uma imagem e mostrar no tamanho original
  const handleImageClick = (index) => {
    setSelectedImage(resizedImages[index]); // Exibe a imagem original ao clicar
  };

  // Função para fechar a imagem em tamanho original
  const handleCloseOriginalImage = () => {
    setSelectedImage(null); // Fecha a visualização da imagem original
  };

  return (
    <div>
      <input type="file" multiple onChange={handleImageUpload} />

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
        {resizedImages.map((image, index) => (
          <div key={index} style={{ margin: '10px', position: 'relative' }}>
            <img
              src={image}
              alt={`Imagem ${index + 1}`}
              style={{
                maxWidth: '300px',
                maxHeight: '225px',
                marginBottom: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(index)} // Exibe a imagem original ao clicar
            />
            <input type="button"
              onClick={() => handleDeleteImage(index)}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                backgroundColor: 'red',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                padding: '5px',
              }}
              value='Excluir' />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          onClick={handleCloseOriginalImage}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img
            src={selectedImage}
            alt="Imagem Original"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              borderRadius: '10px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
