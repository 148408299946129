import React from 'react';
import ReactDOM from 'react-dom/client';
import Customer from './Customer';
import EntryEditor from './EntryEditor';
import Service from './Service';
import Expired2 from './Expired2';
import NavBar from './NavBar';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext';
import { fields as customerFields } from './Customer';
import { fields as serviceFields } from './Service';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './index.css';

import {
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';

import Root from './routes/root';

function Frame(page) {
	return (
	 <AuthProvider>
	  <NavBar />
	  {page}
	 </AuthProvider>
	)
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <AuthProvider><Root/></AuthProvider>
	},
	{
		path: "/expired2",
		element: Frame(<Expired2/>)
	},
	{
		path: "/customer",
		element: Frame(<Customer/>)
	},
	{
		path: "/customers/:customerId/services/list",
		element: Frame(<Service/>)
	},
	{
		path: "/customers/edit",
		element: Frame(<EntryEditor fields={customerFields} endpoint='customers'/>)
	},
	{
		path: "/services/edit",
		element: Frame(<EntryEditor fields={serviceFields} endpoint='services'/>)
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
