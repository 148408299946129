import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Api from "./Api";
import { AuthContext } from './AuthContext';
import XTable from "./XTable";
import { pastMidnight, timestampToBrDate } from "./Utils";

const Expired2 = () => {
	const navigate = useNavigate();

	const [divs, setDivs] = useState([]);

	const {auth, setAuth} = useContext(AuthContext);

	const API_URL = process.env.REACT_APP_BASE_API_URL + '/api/v1/servicesexpired';

	const [popup, setPopup] = useState({ show: false, targetId: null, newStatus: null });
	const [duplicate, setDuplicate] = useState(false);

	const showPopup = (id, customer_id = -1, status, fetchData) => {
		setPopup({ show: true, targetId: id, customer_id: customer_id, status: status, fetchData: fetchData });
	};

	async function updateServiceStatus(newStatus, id, fetchData) {
		const get_url = process.env.REACT_APP_BASE_API_URL + '/api/v1/services/' + id;
		const upsert_url = process.env.REACT_APP_BASE_API_URL + '/api/v1/services';
		Api({auth: auth}).fetchData(get_url)
		.then(result => result )
		.then(item => {
			item.status = newStatus;
			return Api({auth: auth, API_URL: upsert_url}).upsertItem(item)
			.then(() => duplicate && Api({auth: auth, API_URL: upsert_url}).duplicateService(item.id))
			.then(() => {
				if (fetchData)
					fetchData();
			});
		});
	}

	const handleOptionClick = (newStatus, id, fetchData) => {
		updateServiceStatus(newStatus, id, fetchData).then();
		setPopup({ show: false, targetId: null, status: null,fetchData: null }); // Fechar o popup
		setDuplicate(false);
	};

	const closePopup = () => {
		setPopup({ show: false, targetId: null, status: null,fetchData: null });
	};

	function handleDuplicateChanged() {
		setDuplicate(!duplicate);
	}


	let options = null;
	if (popup.status == 'Novo')
		options = (
			<button className='blue' onClick={() => handleOptionClick('Contato realizado', popup.targetId, popup.fetchData)}>
			Contato realizado
			</button>
		);
	else
		options = (
			<>
			 <div className="form-check" style={{margin: "5px" }}>
			  <input className="form-check-input" type="checkbox" id="duplicate" checked={duplicate} onChange={handleDuplicateChanged} />
			  <label className="form-check-label" style={{textAlign: "left", width: "100%"}} for="duplicate">Criar novo para contato futuro</label>
			 </div>
			 <button className='blue' onClick={() => handleOptionClick('Finalizado', popup.targetId, popup.fetchData)}>
			  Marcar como Finalizado
			 </button>
			 <button className='yellow' onClick={() => handleOptionClick('Recusado', popup.targetId, popup.fetchData)}>
			  Marcar como Recusado
			 </button>
			</>
		);


	function classFunction(status) {
			 if (status == 'Contato realizado')
			   return 'yellow-box';
			return '';
	}

	return (
		<>
		<XTable
			renderButtonNew={false}
			classFunction={classFunction}
			endpoint="servicesexpired"
			fields={{
				name: {title: 'Nome'},
				phone: {title: 'Telefone'},
				brand: {title: 'Marca' },
				type: {title: 'Tipo' },
				model: {title: 'Modelo' },
				date: {
					title: 'Data',
					convert: timestampToBrDate,
				},
				status: {title: 'Status'},
				id: { from: 'service_id' },
				customer_id: {hidden: true}, /* TODO: workaround to pass customer_id */
			}}
			buttons={(fetchData) => (item) =>
				<div style={{display: 'flex'}}>
				    <button className='wa' style={{width: '100%'}} onClick={() => {
					window.open('https://wa.me/55' + item.phone.replace(/\D/g, ''), '_blank')
				    }}>
				      <i class="bi bi-whatsapp"></i>
				    </button>
				   <button className='blue' style={{width: '100%'}} onClick={() => showPopup(item.id, item.customer_id, item.status, fetchData)}>Ações</button>
				</div>
			}
		/>

		{popup.show && (
		  <div className="popup">
		    <div className="popup-content">
		      <p>Escolha uma opção:</p>
		      {options}
		      <button className='black' onClick={() => navigate('/customers/' + popup.customer_id + '/services/list')}>
		        Ir para registro do cliente
		      </button>
		      <button className='black' onClick={closePopup}>Cancelar</button>
		    </div>
		  </div>
		)}
		</>
	)
};

export default Expired2;
