import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";

import { nonEmptyValidator } from './Validators';
import XTable from "./XTable";

export const fields = {
	name: {
		title: 'Nome',
		validator: nonEmptyValidator,
		validatorMessage: "Nome não pode ser vazio",
	},
	address: {
		title: 'Endereço',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	streetnumber: {
		title: 'Número',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	complement: {
		title: 'Complemento',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	city: {
		title: 'Cidade',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	cep: {
		title: 'CEP',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	phone: {
		title: 'Telefone',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
	cpf: {
		title: 'CPF',
		validator: () => true,
		validatorMessage: "TODO: error message",
	},
};

function Customer() {
	const navigate = useNavigate();

	return (
		<XTable
			editRoute='/customers/edit'
			endpoint="customers"
			fields={fields}
			extraButtons={(item) => 
				<button style={{width: '100%'}} className="btn btn-secondary black" onClick={() =>
					navigate('/customers/' + item.id + '/services/list')
				}>
					Serviços
				</button>
			}
		/>
	);
}

export default Customer;
