import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Api from "./Api";
import { AuthContext } from './AuthContext';

function XTable({renderButtonNew, endpoint, editRoute, fields, extraButtons, buttons, parentId, classFunction}) {
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [filter, setFilter] = useState("");
	const [loading, setLoading] = useState(false);
	const {auth, setAuth} = useContext(AuthContext);
	const [popup, setPopup] = useState({show: false, id: null});

	const API_URL = process.env.REACT_APP_BASE_API_URL + '/api/v1/' + endpoint;

	const fieldsNames = Object.keys(fields);

	function fetchData() {
		setLoading(true);
		Api({auth: auth, API_URL: API_URL}).fetchData()
			.then((result) => {
				/*
				 * Remove as colunas que não foram
				 * especificadas na variável "fields". TODO: ao
				 * invés de fazer esse tratamento no frontend,
				 * o melhor é passar a lista de colunas
				 * desejadas para o backend entregar apenas a
				 * informação que importa.
				 */
				const result2 = result.map(item => {
					/*
					 * Optionally, we can set the object id
					 * from another field, if passed in the
					 * "from" attribute of "id".
					 */
					const id_from = fields.id?.from ? item[fields.id.from] : item.id;
					const item2 = Object.keys(item).reduce((item2, key) => {
						if (fieldsNames.includes(key)) {
							if (fields[key].convert)
								item2[key] = fields[key].convert(item[key]);
							else
								item2[key] = item[key];
						}
						return item2;
					}, {id: id_from}); /* Don't exclude id */
					return item2;
				});
				setData(result2)
				setLoading(false);
			})
			.catch(e => console.log('TODO: adicionar error handling'));
	}

	function upsertItem(item) {
		Api({auth: auth, API_URL: API_URL}).upsertItem(item);
	}

	async function deleteItem(id) {
		return Api({auth: auth, API_URL: API_URL}).deleteItem(id);
	}

	useEffect(() => {
		fetchData();
	}, []);


	if (loading)
		return "Carregando...";

	function showDeletePopup(id) {
		setPopup({ show: true, id: id});
	}

	var myButtons;
	if (buttons)
		myButtons = buttons(fetchData);
	else
		myButtons = (item) => (
			<Container>
			<Row>
			<Col xs={12}>
			<button style={{width: '100%'}} className="btn btn-secondary black"
			 type="button" onClick={() => {
			 	navigate(editRoute, {state: {
			 		item: item,
			 	}})
			 }}>
				Editar
			</button>
			{extraButtons?.(item)}
			<button style={{width: '100%'}} className="btn btn-secondary red"
			 type="button" onClick={() => {showDeletePopup(item.id)}}>
				Excluir
			</button>
			</Col>
			</Row>
			</Container>
		);

	let classFunc = classFunction;
	if (!classFunc)
		classFunc = () => "x";

	const lines = data?.map((item) => {
		if (filter !== ""
		&& !Object.values(item).some(
		 value =>

		  /*
		   * TODO: why did we need it? If we don't have it, it fails
		   * when searching for something in the Services page
		   */
		  value && 

		  value.toString().toLowerCase().includes(
		   filter.trim().toLowerCase())
		  ))
			return;
		/*
		 * We filter out 'id' because we don't need to show it and it is
		 * not defined in the fields array
		 */
		return (
			<tr style={{margin: '10px'}} className={`${classFunc(item.status)}`}>
			{Object.keys(item).filter(key => key != 'id').map(key => {
				if (fields[key].hidden && fields[key].hidden == true)
					return;
				return <td className="bg-transparent">{item[key]}</td>
			})}
			<td className='bg-transparent'>{myButtons(item)}</td>
			</tr>
		)
	});

	let buttonNew = <></>;
	if (renderButtonNew !== false)
		buttonNew = 
		<button id='novo' className='blue' onClick={() => {
			 	navigate(editRoute, {state: {
			 		parentField: 'customer_id',
			 		parentId: parentId,
			 	}})
			 }}>
			Inserir novo registro
		</button>

	return (
		<>
    <div className='form-group'>
    <input className='form-control' placeholder="Buscar..." onChange={(e) => setFilter(e.target.value)} />
    </div>

    <br/>

    {buttonNew}
		<Table responsive hover size="sm">
		  <thead>
		  <tr>
			{Object.keys(fields).filter(key => key != 'id' && fields[key].hidden !== true).map(key => {
        return <th>{fields[key].title}</th>
			})}
			<th>Ações</th>
      </tr>
      </thead>
      <tbody>
	    {lines}
	    </tbody>
		</Table>

		{popup.show && (
		 <div className="popup">
		   <div className="popup-content">
		     <p>Tem certeza que deseja excluir esse registro?</p>
		     <button className='red' onClick={() => {
		     	deleteItem(popup.id).then(() => fetchData());
		     	setPopup({show: false, id: null});
		     }}>
		       Excluir
		     </button>
		     <button className='black' onClick={() => setPopup({show: false, id: null})}>
		       Cancelar
		     </button>
		   </div>
		 </div>
		)}
		</>
	)
}

export default XTable;
