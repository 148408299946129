import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

function ComboBox({id, value, onChange, options}) {
	const [myValue, setMyValue] = useState(value);

	return (
		<Form.Select
			id={id}
			value={value}
			onChange={(e) => {
				setMyValue(e.target.value);
				onChange(id, e.target.value);
			}}
		>
			{options.map(key => <option value={key}>{key}</option>)}
		</Form.Select>
	);
}

export default ComboBox;
