export default function ApiInterface({auth, API_URL}) {
	return {
	fetchData: async function(url = undefined) {
		var api_url = API_URL + '/list';
		/* url is an optional parameter to override API_URL */
		if (url)
			api_url = url;
		try {
			const response = await fetch(api_url, {
				method: 'GET',
				headers: {
					Authorization: 'Basic '
					  + btoa(auth.username + ':' + auth.password),
				},
			});
			if (!response.ok) {
				throw new Error('Falha ao obter os dados da API');
			}
			const result = await response.json();
			return result;
		} catch (error) {
			throw new Error('Erro ao obter os dados:');
		}
	},

	login: async function() {
		var api_url = API_URL + '/api/v1/login';
		try {
			const response = await fetch(api_url, {
				method: 'POST',
				headers: {
					Authorization: 'Basic '
					  + btoa(auth.username + ':' + auth.password),
				},
			});
			if (!response.ok)
				return false;
			return true;
		} catch (error) {
			return false;
		}
	},

	/*
	 * TODO: if failing to delete an item, show message error and call
	 * fetchData() again
	 */
	deleteItem: async function(id) {
		const api_url = API_URL + '/' + id;
		try {
			const response = await fetch(api_url, {
				method: 'DELETE',
				headers: {
					Authorization: 'Basic '
					  + btoa(auth.username + ':' + auth.password),
				},
			});
			if (!response.ok) {
				throw new Error('Falha ao obter os dados da API');
			}
		} catch (error) {
			console.error('Erro ao obter os dados:', error);
		}
	},

	/*
	 * TODO: if failing to upsert an item, show message error and call
	 * fetchData() again
	 */
	upsertItem: async function(item) {
		const api_url = API_URL + '/upsert';
		try {
			const response = await fetch(api_url, {
				method: 'POST',
				headers: {
					Authorization: 'Basic '
					  + btoa(auth.username + ':' + auth.password),
			                "Content-Type": 'application/json;charset=UTF-8',
				},
				body: JSON.stringify(item),
			});
			if (!response.ok) {
				throw new Error('Falha ao obter os dados da API');
			}
		} catch (error) {
			console.error('Erro ao obter os dados:', error);
		}
	},

	duplicateService: async function(id) {
		const api_url = API_URL + '/duplicate/' + id;
		try {
			const response = await fetch(api_url, {
				method: 'POST',
				headers: {
					Authorization: 'Basic '
					  + btoa(auth.username + ':' + auth.password),
			                "Content-Type": 'application/json;charset=UTF-8',
				},
				body: JSON.stringify(id),
			});
			if (!response.ok) {
				throw new Error('Falha ao obter os dados da API');
			}
		} catch (error) {
			console.error('Erro ao obter os dados:', error);
		}
	}
}
}
